.reveal {
  .reveal__section {
    position: relative;
    width: 100%;
    margin-top: 80px;

    .reveal__intro {
      position: relative;
      width: 100%;
      margin-bottom: 1rem;

      p {
        margin-bottom: 1rem;
        text-align: left;
        font-size: 0.9rem;
      }
    }

    .error__banner {
      margin-bottom: 1rem;
    }

    .reveal__field {
      position: relative;
      overflow: hidden;
      display: block;
      width: 100%;
      height: 200px;
      max-width: 35rem;

      .reveal__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background: $grey-light;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 0.5s ease-in-out;
        z-index: 9;

        h2 {
          position: absolute;
          padding: 1rem;
          color: $white;
          font-size: $size-5;
          font-weight: $weight-bold;
          background: $black;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &--hide {
          top: -100%;
        }
      }

      .field__token {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 200px;
        padding: 10px;
        background: $white;
        z-index: 8;

        .field__token-input {
          position: relative;
          width: 100%;
          height: 138px;
          margin: 0;
          padding: 1px 2px;
          font-size: 1.4rem;
          font-family: $family-monospace;
          color: $grey;
          border-radius: 0;
          outline: none;
          box-shadow: none;
          border: none;
          resize: none;
          z-index: 8;
        }

        .field__copy {
          width: 100px;
          height: 38px;
          align-self: flex-end;
          border-radius: 3px;
          z-index: 8;
        }
      }
    }
  }
}
