header {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 80px;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 1rem;
  z-index: 99;

  .brand {
    position: relative;
    overflow: hidden;
    display: block;
    font-size: 1.4rem;
    color: $dark;
    font-weight: $weight-bold;
    margin: 2rem;
  }
}
