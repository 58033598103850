.page--home {
  position: relative;

  header {
    position: relative;

    @include desktop() {
      max-width: 45rem;
      margin: 0 auto;
    }
  }

  .panel__section {
    position: relative;
    z-index: 99;

    .panel__container {
      @extend .container;

      .panel__cell {
        color: $dark;

        h1,
        h2,
        p {
          text-align: center;
        }

        p {
          font-size: 1.2rem;
        }
      }
    }

    &.panel__section--top {
      background-image: waves($primary);
      background-color: $white;
      background-position: center bottom;
      background-size: 200% auto;
      background-repeat: no-repeat;
      padding-bottom: 30%;

      .call-to-action {
        display: flex;
        flex-direction: column;

        .button {
          font-size: 1.2rem;

          span {
            display: inline-block;
            font-size: 1.2rem;
          }

          .feather {
            position: relative;
            display: inline-block;
            margin: 3px 0 0 10px;
          }

          &:first-child {
            margin-bottom: 1rem;
          }
        }
      }

      @include tablet() {
        padding-bottom: 22%;

        .call-to-action {
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 2rem 0;

          .button {
            &:first-child {
              margin-bottom: 0;
              margin-right: 1rem;
            }
          }
        }
      }

      @include desktop() {
        padding-top: 60px;
        padding-bottom: 12%;
        background-size: 100% auto;
      }
    }

    &.panel__section--middle {
      background-color: $primary;
      margin-top: -3px;
      padding-top: 0;

      .panel__container {
        .panel__cell {
          font-size: 1.2rem;
          color: $white;

          .features {
            li {
              position: relative;
              margin-bottom: 0.5rem;
              padding: 1rem;
              font-size: 1rem;
              color: $black;
              background: lighten($primary, 30%);
              border-radius: 0.3rem;
            }
          }

          .signup {
            margin-top: 2rem;
          }

          p {
            strong {
              color: $white;
            }
          }
        }
      }
    }

    &.panel__section--about {
      padding-bottom: 350px;
      background-color: $primary;

      .panel__container {
        .panel__cell {
          font-size: 1.2rem;
          color: $white;
        }
      }
    }

    &.panel__section--form {
      background-color: transparent;
      margin-top: -305px;
      padding-bottom: 50px;

      .panel__container {
        .panel__cell {
          h2,
          p {
            color: $white;
          }

          .loading__button {
            height: 70px;
            background: $dark;
            border: 1px solid $white;
          }

          .field__email {
            background: $white;
          }
        }
      }

      @include desktop() {
        margin-top: -210px;

        .field__email {
          height: 70px;

          .field__email-input {
            height: 60px;
          }

          .field__send {
            height: 58px;
            font-size: 1.2rem;
          }
        }
      }
    }

    &.panel__section--footer {
      @include tablet() {
        padding-bottom: 100px;
      }
    }
  }
}
