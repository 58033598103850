.form__share-token {
  width: 360px;

  .success__banner {
    position: relative;
    width: 100%;
    min-height: 50px;
    margin-bottom: 1rem;
    padding: 0.6rem 1rem;
    background: $success-light;
    border-radius: 0.3rem;

    p {
      margin: 0;
      color: $success;
    }
  }

  .error__banner {
    margin-bottom: 1rem;
  }

  .form__field {
    position: relative;
    margin-bottom: 1rem;

    label {
      font-family: $family-sans-serif;
      font-weight: 700;
      font-size: 0.9rem;

      input,
      textarea {
        border: 1px solid $semi-dark;
        border-radius: 0.3rem;
      }

      textarea {
        min-height: 80px;
        font-size: 0.9rem;
      }
    }

    button {
      width: 100%;
    }
  }
}
