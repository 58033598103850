.form__copy-token {
  .field__token {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 162px;
    padding: 10px;
    background: $white;

    .field__token-input {
      position: relative;
      width: 100%;
      height: 100px;
      margin: 0;
      padding: 1px 2px;
      font-size: 1.4rem;
      font-family: $family-monospace;
      color: $grey;
      border-radius: 0;
      outline: none;
      box-shadow: none;
      border: none;
      resize: none;
    }

    .field__copy {
      width: 100px;
      height: 38px;
      align-self: flex-end;
      border-radius: 3px;
    }
  }
}
