.loading__button {
  position: relative;
  width: 100%;
  height: 50px;
  background: $primary;
  border-radius: 0.3rem;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 15px;
    margin-top: -7.5px;
    margin-left: -30px;
    background-image: url("../../images/three-dots.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60px 15px;
  }
}
