.field__password {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  padding: 10px;
  background: $white;

  .field__password-input {
    position: relative;
    width: 100%;
    height: 138px;
    margin: 0;
    font-size: 1.4rem;
    font-family: $family-monospace;
    color: $grey;
    outline: none;
    resize: none;
  }

  .field__generate-password {
    position: relative;
    overflow: hidden;
    align-self: flex-end;
    width: 100%;
    height: 40px;
    flex-basis: 40px;
    margin: 0;
    padding: 0 50px 0 0;
    border: none;
    cursor: pointer;
    outline: none;
    background: none;
    text-align: right;
    font-size: 0.9rem;
    font-weight: bold;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: transparent;
      background-image: refresh($black);
      background-position: center center;
      background-size: 24px 24px;
      background-repeat: no-repeat;
      transform: rotate(360deg);
      transition: transform 0.5s ease;
    }

    &:hover {
      &::after {
        background-image: refresh($primary);
        transform: rotate(0deg);
      }
    }
  }
}
