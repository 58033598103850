html {
  body {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-family: $family-sans-serif;
    color: $black;
    background: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      font-family: $family-sans-serif;
      margin-bottom: 20px;
    }
  }

  @include desktop() {
    min-height: 100%;
    body {
      min-height: 100%;
      #root {
        min-height: 100%;
        .app {
          min-height: 100%;
        }
      }
    }
  }
}
