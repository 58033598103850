/* ENTER TRANSITION */
/* Declare transition start properties*/
.slide-enter {
    position: absolute;
    transform: translateX(-400px);
    opacity: 0;
}

/* Declare transition properties */
.slide-enter.slide-enter-active {
    position: relative;
    transform: translateX(0);
    opacity: 1;
    transition: all 300ms linear 300ms;
}

/* EXIT TRANSITION */
.slide-leave {
    transform: translateX(0);
    opacity: 1;
}

.slide-leave.slide-leave-active {
    position: absolute;
    transform: translateX(400px);
    opacity: 0;
    transition: all 300ms linear
}
