.back-button {
  position: relative;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 0.5rem 2rem;
  font-weight: 700;
  background: transparent;
  border: none;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0.5rem;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-image: url("../../images/icons/arrow-left.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center center;
    transition: left 0.3s ease-in-out;
  }

  &:hover {
    &::after {
      left: 0.2rem;
    }
  }
}
