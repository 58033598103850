@charset "utf-8";

// Fonts
@import url("https://fonts.googleapis.com/css?family=Muli:wght@400;700&display=swap");

// Bulma
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/functions";

$family-serif: "Cormorant", "Georgia", serif;
$family-sans-serif: "Muli", "Arial", serif;

$primary: #12a4ef;
$secondary: #9cd8d0;
$grey-light: #efefef;
$primary-invert: $white;
$secondary-invert: $primary;
$sand: #fef7de;
$sand-invert: darken($sand, 10%);
$semi-dark: #3a2f34;
$semi-dark-invert: $white;
$black: #000000;

$body-size: 1.1rem;

$family-primary: $family-sans-serif;

@import "~bulma/sass/utilities/derived-variables";

$addColors: (
  "secondary": (
    $secondary,
    $secondary-invert,
  ),
  "sand": (
    $sand,
    $sand-invert,
  ),
  "semi-dark": (
    $semi-dark,
    $semi-dark-invert,
  ),
);
$colors: map-merge($colors, $addColors);

@import "~bulma";

// Functions
@import "./utils/functions";
@import "./utils/svgs";

// Global
@import "./global";

// Fields
@import "./components/form/fields/field";
@import "./components/form/fields/input";
@import "./components/form/fields/email";
@import "./components/form/fields/password";
@import "./components/form/fields/button";

// Forms
@import "./components/form/copy-token";
@import "./components/form/share-token";
@import "./components/form/store-password";

// Components
@import "./components/store-send";
@import "./components/reveal";
@import "./components/panel-section";
@import "./components/wallpaper";
@import "./components/loading";
@import "./components/error";
@import "./components/back-button";

// Layouts
@import "./layouts/header";

// Pages
@import "./pages/home";
@import "./pages/secret";
@import "./pages/reveal";

//Utils
@import "./utils/transitions";
