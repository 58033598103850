.error__banner {
  position: relative;
  width: 100%;
  min-height: 50px;
  padding: 0.6rem 1rem;
  background: $danger-light;
  border-radius: 0.3rem;

  p {
    margin: 0;
    color: $danger;
  }
}
