.page--reveal {
  height: 100%;

  .panel__section {
    @include desktop {
      justify-content: flex-end;
    }

    .panel__container {
      @include desktop {
        height: 100%;
        justify-content: flex-start;
      }

      .panel__cell {
        overflow: hidden;
      }
    }
  }
}
