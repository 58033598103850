.page--secret {
  min-height: 100%;

  .panel__section {
    @include desktop {
      min-height: 100%;
      justify-content: flex-end;
    }

    .panel__container {
      @include desktop {
        min-height: 100%;
        justify-content: flex-start;
      }

      .panel__cell {
        .form__store-password {
          width: 100%;
        }

        .form__share-token {
          width: 100%;
        }

        .button__copy-step {
          width: 100%;
        }
      }
    }
  }
}
