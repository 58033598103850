.input {
    &--no-border {
        border: none;
        box-shadow: none;
        outline: none;
    }

    &--no-bg {
        background: transparent;
    }
}