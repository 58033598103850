.field {
  position: relative;
  width: 100%;
  height: 50px;
  margin-bottom: 0.5rem;
  padding: 5px 0.5rem;
  border: 1px solid $semi-dark;
  border-radius: 0.3rem;

  input {
    font-size: 1.1rem;
  }
}
