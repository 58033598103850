.field__email {
  display: flex;
  flex-direction: row;
  padding: 5px 5px 5px 0.5rem;

  .field__email-input {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 0 0.5rem 0 0;
    padding: 1px 2px;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    border: none;
  }

  .field__send {
    height: 38px;
    border-radius: none;
  }
}
