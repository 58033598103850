.form__store-password {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 360px;

  .form__store-button {
    display: block;
    width: 100%;
    height: 50px;
  }
}
