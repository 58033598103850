.wallpaper {
  position: relative;
  background: $black;
  padding: 2rem;
  padding-right: 1rem;
  z-index: 99;

  .wallpaper__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    background-color: transparent;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &--loaded {
      opacity: 0.6;
    }
  }

  .wallpaper__heading {
    position: relative;
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: $weight-normal;
    color: $white;
    z-index: 99;

    .button {
      font-size: 1.2rem;
      font-weight: $weight-normal;
    }
  }

  @include desktop() {
    position: fixed;
    height: 100%;
    top: 0;
    left: 30rem;
    right: 0;
    bottom: 0;
    padding: 0;

    .wallpaper__heading {
      max-width: 20rem;
      margin-top: 4rem;
      margin-left: 4rem;
      border-left: 3px solid $white;
      padding: 0 1rem;
    }
  }
}
