.panel__section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;
  box-sizing: border-box;

  /* panel content (only for animation delay after open) */
  .panel__container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    will-change: margin-top;
    transition: all 700ms;
    transition-delay: 600ms;

    @include desktop {
      flex-direction: row;
      height: 100%;
    }

    .panel__cell {
      position: relative;
      padding: 2rem;

      &.one-third {
        @include tablet {
          max-width: 30rem;
        }
      }

      &.half {
        width: 100%;

        @include desktop {
          justify-self: center;
          max-width: 45rem;
        }
      }

      &.two-thirds {
        width: 100%;

        @include desktop {
          max-width: 60rem;
        }
      }

      &.padded {
        padding: 2rem;
      }
    }
  }

  /* Panel content animation after open */
  &.active {
    .panel__container {
      opacity: 1;
      margin-top: 0;
    }
  }

  &.reveal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 9;
    background-color: $primary;
    transition: all 800ms cubic-bezier(0.19, 1, 0.56, 1);
    transform: translate3d(0, -100%, 0);

    &.active {
      transform: translate3d(0, 0, 0);
    }
  }
}
